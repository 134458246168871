import * as React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../components/layout";

interface IAllLinksJson {
	allLinksJson: {
		edges: Array<{
			node: {
				name: string;
				links: Array<{ url: string; description: string; name: string }>;
			};
		}>;
	};
}

const LinksPage: React.FunctionComponent<{
	data: IAllLinksJson;
}> = ({ data }) => {
	return (
		<DefaultLayout>
			<h1>Links</h1>
			{data.allLinksJson.edges.map(({ node }) => (
				<React.Fragment key={node.name}>
					<h2>{node.name}</h2>
					<dl>
						{node.links.map(link => (
							<React.Fragment key={link.url}>
								<dt>
									<a href={link.url}>{link.name}</a>
								</dt>
								{link.description && (
									<dd dangerouslySetInnerHTML={{ __html: link.description }} />
								)}
							</React.Fragment>
						))}
					</dl>
				</React.Fragment>
			))}
		</DefaultLayout>
	);
};

export default LinksPage;

export const todoFragment = graphql`
	query LinksPage {
		allLinksJson {
			edges {
				node {
					name
					links {
						url
						name
						description
					}
				}
			}
		}
	}
`;
