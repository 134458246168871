module.exports = {
	backgroundColor: "#222222",
	themeColor: "rgb(92, 194, 101)",
	textColor: "rgb(237, 237, 237)",
	responsiveBreakpoint: "800px",
	folderToCategory: {
		posts: "Post",
		books: "Livro",
		bookmarks: "Recomendação",
		apresentacoes: "Apresentação",
	},
};
